/* No TCF stub. Adds consent pixels and close button */

window.cpexCmpVersion = '2.2.1'

window.didomiEventListeners = window.didomiEventListeners || []
window.didomiOnReady = window.didomiOnReady || []

window.cpexAddCMPCloseButton = function () {
  const targetEl = document.getElementById('didomi-notice') || document.getElementsByClassName('didomi-popup-notice-logo-container')[0] // For both banner and popup
  if (targetEl) {
    const closeButton = document.createElement('div')
    closeButton.textContent = '×'
    closeButton.style.cssText = 'z-index: 2147483641; position: absolute; top: 10px; right: 10px; width: 40px; height: 40px; cursor: pointer; font-size: 20pt; text-align: center; line-height: 40px'
    closeButton.addEventListener('click', function () { Didomi.notice.hide() })
    targetEl.appendChild(closeButton)
  }
};

(function () {
  /**
   * UTILS
   */

  function cpexLog () {
    if (window.location.href.indexOf('debug') > -1) { console.log.apply(this, arguments) }
  }

  /**
   * ADD CLOSE BUTTON
   */

  if (typeof window.cpexCmpAllowCloseButton !== 'undefined' || window.location.href.indexOf('cpexCmpAllowCloseButton') !== -1) {
    window.didomiOnReady.push(window.cpexAddCMPCloseButton)
  }

  /**
   * CONSENT PIXELS
   */

  var vendor = document.getElementById('cpex-cmp-script') ? document.getElementById('cpex-cmp-script').dataset.vendor : 'any'
  var pixelLists = {
    any: [
      'https://a.slunecnice.cz/slunecnice/SETSV/TTL=33696000/consent=%CONSENT%/GDPR=1',
      'https://a.denik.cz/vlm/SETSV/TTL=33696000/consent=%CONSENT%/GDPR=1',
      'https://a.1gr.cz/mafra/SETSV/TTL=33696000/consent=%CONSENT%/GDPR=1',
      'https://a.centrum.cz/cent/SETSV/TTL=33696000/consent=%CONSENT%/GDPR=1',
      'https://a.csfd.cz/csfd/SETSV/TTL=33696000/consent=%CONSENT%/GDPR=1'
    ]
  }

  function addConsentPixel (url) {
    const el = document.createElement('img')
    el.setAttribute('src', url)
    el.style.width = '1px'
    el.style.height = '1px'
    el.style.position = 'absolute'
    el.style.bottom = '0px'
    el.style.right = '0px'
    el.style.border = 'none'
    document.body.appendChild(el)
  }

  function fireConsentPixel (data, success) {
    if (success) {
      if (data.vendor.consents) {
        if (Object.keys(data.vendor.consents).length > 0) {
          pixelLists[vendor].forEach(function (url) {
            addConsentPixel(url.replace('%CONSENT%', data.tcString))
          })
          localStorage.setItem('pixelsLastFired', Date.now())
          cpexLog('CMP: added consent pixels')
        }
      }
    }
  }

  function fireConsentPixels (ignoreTimer) {
    if (window.Didomi?.getConfig().cookies?.group.customDomain === 'privacy.cpex.cz') {
      cpexLog('CMP: trying to fire consent pixels')
      // if 24 hours passed, fire consent pixels
      var pixelsLastFired = localStorage.getItem('pixelsLastFired') || 0
      var intervalMs = 24 * 60 * 60 * 1000
      if (Date.now() - intervalMs > pixelsLastFired || ignoreTimer) {
        __tcfapi('addEventListener', 2, fireConsentPixel)
      } else {
        cpexLog('CMP: 24 hours have not yet passed, based on pixelsLastFired in local storage')
      }
    }
  }

  window.didomiEventListeners.push({ event: 'consent.changed', listener: function (context) { fireConsentPixels(true) } })
  if (document.readyState === 'complete') {
    fireConsentPixels()
  } else {
    window.addEventListener('load', function () { fireConsentPixels() })
  }
})()
